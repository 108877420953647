<template>
    <div class="newbill">
        <!-- 条件搜索框 -->
        <div class="flex-nowrap-space-between mb-20">
            <div class="flex-nowrap-space-around">
                <div class="mr-20 first-name">时间选择</div>
                <el-radio-group v-model="time" size="small" @change="setTimeData" >
                    <el-radio-button label="1">今日</el-radio-button>
                    <el-radio-button label="2">昨天</el-radio-button>
                    <el-radio-button label="3">本周</el-radio-button>
                    <el-radio-button label="4">上周</el-radio-button>
                    <!-- <el-radio-button label="5">本月</el-radio-button> -->
                    <el-radio-button label="6">上月</el-radio-button>
                </el-radio-group>
                <div class="ml-20">
                    <el-date-picker
                        v-model="timeList"
                        @change="setDataList"
                        :clearable='false'
                        type="daterange"
                        size="small"
                        value-format="YYYY-MM-DD"
                        start-placeholder="开始日期"
                        end-placeholder="结束日期"
                        :disabledDate="disabledDate"
                    >
                    </el-date-picker>
                </div>
            </div>
            <div class="flex-nowrap-flex-start">
                <div class="ml-10">
                    <el-dropdown>
                    <el-button class="button-el" size="medium">
                        <icon-font type="icon-daochushuju" class="button-icon-font"/>
                        导出数据
                        <icon-font type="icon-copy-4-36" class="button-icon-font"/>
                    </el-button>
                    <template #dropdown>
                        <el-dropdown-menu>
                        <el-dropdown-item @click="setSearchExport(2)">搜索结果</el-dropdown-item>
                        </el-dropdown-menu>
                    </template>
                    </el-dropdown>
                </div>
                <div class="font-refresh-out flex-nowrap-center-center ml-10" @click="refreshTable">
                    <icon-font v-if="loading==false" type="icon-shuaxin" class="font-refresh"/>
                    <i v-else class="el-icon-loading loding-color"></i>
                </div>
            </div>
        </div>
        <div class="flex-nowrap-flex-start mb-20">
            <div class="flex-nowrap-space-around">
                <div class="mr-20 first-name">数据维度</div>
                <el-radio-group v-model="type" size="small" @change="setTypeData">
                    <el-radio-button label="amount">
                        <span class="title-span">服务商</span>
                    </el-radio-button>
                    <el-radio-button label="number">
                        <span class="title-span">小号</span>
                    </el-radio-button>
                    <!-- <el-radio-button label="interfaces">
                        <span class="title-span">线路</span>
                    </el-radio-button> -->
                </el-radio-group>
            </div>
            <div class="ml-20">
                <div v-if="type == 'amount'">
                    <el-select
                        v-model="searchFrom.amountId"
                        placeholder="请选择查询的服务商"
                        style="width: 100%"
                        size="small"
                        @change="setDataList"
                        clearable
                        filterable
                    >
                        <el-option
                            v-for="(item, index) in amount_id"
                            :key="index"
                            :label="item.name"
                            :value="item.id"
                        ></el-option>
                    </el-select>
                </div>
                <div v-if="type == 'number'">
                    <el-input
                        size="small"
                        v-model.trim="searchFrom.phone"
                        placeholder="请输入查询的小号"
                        class="input-with-select"
                    >
                        <template #append>
                            <el-button @click="setDataList">搜索</el-button>
                        </template>
                    </el-input>
                </div>
            </div>
        </div>
        <!-- 汇总 -->
        <div>
            <el-table
            class="eltable"
            v-loading="loading"
            ref="multipleTable"
            size="mini"
            :data="countList"
            tooltip-effect="dark"
            style="width: 100%"
            :header-cell-style="{background:'#e0e5ff',color:'#24252F'}"
            row-key="id"
            :max-height="tableHeight"
            default-expand-all
            :row-style="{height:'60px'}"
            :cell-style="{padding:'0px'}"
            :tree-props="{children: 'children', hasChildren: 'hasChildren'}">
                <el-table-column
                label="汇总">
                <template>{{  }}</template>
                </el-table-column>
                <el-table-column
                v-if="type == 'amount'"
                label="服务商(数量)">
                <template #default="scope">{{ scope.row.amountCount }}</template>
                </el-table-column>
                <el-table-column
                v-if="type == 'number'"
                label="小号(数量)">
                <template #default="scope">{{ scope.row.numberCount }}</template>
                </el-table-column>
                <el-table-column
                v-if="type == 'interfaces'"
                label="线路(数量)">
                <template #default="scope">{{ scope.row.interfaceCount }}</template>
                </el-table-column>
                <el-table-column
                label="呼叫次数">
                <template #default="scope">{{ scope.row.billNum }}</template>
                </el-table-column>
                <el-table-column
                label="接听次数">
                <template #default="scope">{{ scope.row.callNum }}</template>
                </el-table-column>
                <el-table-column
                label="分钟数(分钟)">
                <template #default="scope">{{ scope.row.callTime }}</template>
                </el-table-column>
                <el-table-column
                label="通话费(元)">
                <template #default="scope">{{ scope.row.callCharge }}</template>
                </el-table-column> 
            </el-table>
        </div>
        <!-- 数据 -->
        <div>
            <el-table
            class="eltable"
            v-loading="loading"
            ref="multipleTable"
            size="mini"
            :data="downTable"
            tooltip-effect="dark"
            style="width: 100%"
            :header-cell-style="{background:'#e0e5ff',color:'#24252F'}"
            row-key="id"
            :max-height="tableHeight"
            default-expand-all
            @sort-change="changeTableSort"
            :row-style="{height:'50px'}"
            :cell-style="{padding:'0px'}"
            :tree-props="{children: 'children', hasChildren: 'hasChildren'}">
                <el-table-column
                fixed 
                v-if="type == 'user'"
                label="昵称">
                    <template #default="scope">{{ scope.row.nickname }}</template>
                </el-table-column>
                <el-table-column
                fixed 
                v-if="type == 'number'"
                label="小号">
                    <template #default="scope">{{ scope.row.number }}</template>
                </el-table-column>
                <el-table-column
                fixed 
                v-if="type == 'amount'||type == 'number'"
                label="服务商">
                    <template #default="scope">{{ scope.row.amountName }}</template>
                </el-table-column>
                <el-table-column
                fixed 
                v-if="type == 'interfaces'"
                label="线路">
                    <template #default="scope">{{ scope.row.interfaceName }}</template>
                </el-table-column>
                <el-table-column
                fixed 
                prop="date"
                :sortable="'custom'"
                label="日期 ">
                    <template #default="scope">{{ scope.row.date }}</template>
                </el-table-column>
                <el-table-column
                prop="billNum"
                :sortable="'custom'"
                label="呼叫次数">
                    <template #default="scope">{{ scope.row.billNum }}</template>
                </el-table-column>
                <el-table-column
                prop="callNum"
                :sortable="'custom'"
                label="接听次数">
                    <template #default="scope">{{ scope.row.callNum }}</template>
                </el-table-column>
                <el-table-column
                prop="callTime"
                :sortable="'custom'"
                label="分钟数(分钟)">
                    <template #default="scope">{{ scope.row.callTime }}</template>
                </el-table-column>
                <el-table-column
                prop="callCharge"
                :sortable="'custom'"
                label="通话费(元)">
                    <template #default="scope">{{ scope.row.callCharge }}</template>
                </el-table-column> 
            </el-table>
        </div>
        <!-- 分页 -->
       <div class="flex-nowrap-flex-end pagination-box">
            <el-pagination
            v-model="currentPage"
            background
            :page-sizes="[10, 20, 50, 100 ,200]"
            :page-size="searchFrom.pageSize"
            layout="total, sizes, prev, pager, next, jumper"
            :total="total"
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
            >
            </el-pagination>
       </div>
    </div>
</template>

<script>
import amountts from "@/api/open/privacy/amount";
import userNumberCount from '@/api/open/userNumberCount/userNumberCount'
import { getDate, getMonday , getMonth } from '@/utils/timeToChoose.js'
import { IconFont } from "@/utils/iconfont"
import { message } from "ant-design-vue";
export default {
    name:"Newbill",
    components:{
        IconFont,
    },
    data(){
        return{
            time:'1',
            total:0,
            type:'amount',
            timeList:[],
            loading:false,
            countList:[],
            downTable:[],
            searchFrom:{
                "page":1,
                "pageSize":20,
                "groupBy": ["amount"],
                "sortBy":['date'],
                "sortDesc":[true],
                "dateBegin":"2021-10-10",
                "dateEnd":"2021-10-19"
            },
            currentPage:1,
            amount_id: [], // 拿到的通信账户类型
            tableHeight:540,
            defaultTime: [
                new Date(2000, 1, 1, 12, 0, 0),
                new Date(2000, 2, 1, 8, 0, 0),
            ], // '12:00:00', '08:00:00'
        }
    },
    watch:{
        // type(news,old){
        //     if( news == 'user' ){
        //         this.tableHeight = 540
        //     }else{
        //         this.tableHeight = 640
        //     }
        // }
    },
    mounted(){
        this.setTimeData()
        this.getDownLowData()
    },
    methods:{
          disabledDate(time) {
    const today = new Date();
    today.setHours(0, 0, 0, 0); 
    return time.getTime() >= today.getTime();
  },
        // 获取下拉数据
        getDownLowData(){
            amountts.getAmountList({}).then((res) => {
                this.amount_id = res.data;
            });
            // userts.queryList({}).then((res) => {
            //     this.user_list = res.data;
            // });
            // interfacets.getInterface({}).then((res) => {
            //     this.interface_id = res.data
            // });
        },
        getDownList(loading){
            this.loading = loading
            userNumberCount.accountList({
                ...this.searchFrom
            }).then((res) => {
                let newListData = []
                newListData.push(res.data.count)
                if(this.type=='amount'){
                    res.data.count.amountCount == 0||res.data.count.amountCount == undefined?this.countList = []:this.countList = [res.data.count]
                }
                if(this.type=='user'){
                    res.data.count.userCount == 0||res.data.count.userCount == undefined?this.countList = []:this.countList = [res.data.count]
                }
                if(this.type=='number'){
                    res.data.count.numberCount == 0||res.data.count.numberCount == undefined?this.countList = []:this.countList = [res.data.count]
                }
                if(this.type=='interfaces'){
                    res.data.count.interfaceCount == 0||res.data.count.interfaceCount == undefined?this.countList = []:this.countList = [res.data.count]
                }
                this.downTable = res.data.page.records
                this.total = res.data.page.total
                this.loading = false
            })
        },
        // 文件导出
        setSearchExport(type) {
            if(type == 2){
                message.warning("正在导出...，请在消息模块下载");
                userNumberCount.exportList({
                    ...this.searchFrom,
                }).then((res) => {
                    if (res.code != 200) {
                        message.error(res.message);
                    }
                });
            }
        },
        // 刷新
        refreshTable(){
            this.searchFrom.page = this.currentPage
            this.searchFrom.sortBy = ['date']
            this.searchFrom.sortDesc = [true]
            this.time = '1'
            this.setTimeData()
        },
        // 分页
        handleSizeChange(val){
            this.searchFrom.pageSize = val
            this.getDownList(true)
        },
        handleCurrentChange(val){
            this.currentPage = val
            this.searchFrom.page = val
            this.getDownList(true)
        },
        // 日期选择器获取值
        setDataList(){
            this.time = null
            this.searchFrom.dateBegin = this.timeList[0]
            this.searchFrom.dateEnd = this.timeList[1]
            this.getDownList(true)
        },
        // 时间选择器失去焦点
        setTime(){
            // this.time = null
        },
        // 设置日期时间
        setTimeData(){
            let listDate = this.setDate(this.time)
            this.timeList = this.setDate(this.time)
            this.searchFrom.dateBegin = listDate[0]
            this.searchFrom.dateEnd = listDate[1]
            this.getDownList(true)
        },
        // 获取维度
        setTypeData(){
            this.searchFrom.amountId = ''
            this.searchFrom.phone = ''
            this.searchFrom.groupBy = [this.type]
            this.getDownList(true)
        },
        // 获取日期时间段
        setDate(type){
            switch (type) {
                case "1":
                    return [ getDate(0),getDate(0) ]
                case "2":
                    return [ getDate(-1),getDate(-1) ]
                case "3":
                    return [ getMonday("s",0),getMonday("e",0) ]
                case "4":
                    return [ getMonday("s",-1),getMonday("e",-1) ]
                case "5":
                    return [ getMonth("s",0),getMonth("e",0) ]
                case "6":
                    return [ getMonth("s",-1),getMonth("e",-1) ]
                default:
                    return []
            }
        },
        // 表格排序
        changeTableSort(column){
            if(column.prop == null){
                
            }else{
                this.searchFrom.sortBy = [column.prop]
                column.order=='ascending'?this.searchFrom.sortDesc = [false]:this.searchFrom.sortDesc = [true]
                this.getDownList(true)
            }
        }
    }
    }
</script>
<style lang="scss" scoped>
.newbill{
    padding-right: 1.125rem;
}
.first-name{
    font-size: 14px;
    font-weight: 400;
    color: #131523;
}
.pagination-box{
    height: 60px;
}
.title-span{
    display: inline-block;
    width: 80px;
}
</style>